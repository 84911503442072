<template>
    <div> 
        <IconTopTable />
        
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Zinl">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody id="tbody_Zinl"> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data"  :key="key">         
                            <td>
                                <label class="table-check" >
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >
                                <td v-if="column.Status && column.code == 'zinl_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span> 
                                </td>                     
                                <td v-else-if="column.Status && column.code == 'zinl_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <!-- <td v-if="column.Status && column.code == 'zinl_003'" :code="column.code">                                                           
                                    <span v-html="icon_link(item.drug_id,'zinl')"></span>
                                </td> -->
                                <td v-else-if="column.Status && column.code == 'zinl_004'" :code="column.code">
                                     {{check_empty(item.category)}}
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'zinl_005'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_006'" :code="column.code">                               
                                    <span v-html="rembursement_status_all_type(item)" ></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_038'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_007'" :code="column.code">
                                     {{check_empty(DDMMMYY(item.zinl.date_evaluation)) }}
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_008'" :code="column.code">
                                     {{check_empty(DDMMMYY(item.ma_date))}}
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'zinl_009'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_010'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>                        
                                <td v-else-if="column.Status && column.code == 'zinl_011'" :code="column.code">
                                     {{check_empty(item.firm)}}
                                </td>                           
                                <td v-else-if="column.Status && column.code == 'zinl_012'" :code="column.code">                               
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>                             
                                <td v-else-if="column.Status && column.code == 'zinl_013'" :code="column.code">                                                                        
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>         
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_014'" :code="column.code">
                                     {{check_empty(item.prioritis_hta_index) }}
                                </td>
                                
                                <template v-else-if="column.Status && column.code == 'zinl_015'">                                    
                                    <td :key="column.code+'_016'" code="zinl_016">                                        
                                        <Readmore :longText="item.target_population"/>                           
                                    </td>
                                    <td :key="column.code+'_017'" code="zinl_017">                                    
                                        <Readmore :longText="item.prescription_and_delivery_conditions"/>                               
                                    </td>
                                    <td :key="column.code+'_018'" code="zinl_018">                                         
                                        <Readmore :longText="item.access_scheme"/>                               
                                    </td>
                                    <td :key="column.code+'_019'" code="zinl_019">                                        
                                        <Readmore :longText="item.outcome_level"/>  
                                    </td>
                                </template>
                                <td v-else-if="column.Status && column.code == 'zinl_020'" :code="column.code">                                                                                                       
                                    <Readmore :longText="item.rationale_and_comments_html_en"/>  
                                </td>                          
                                <td v-else-if="column.Status && column.code == 'zinl_021'" :code="column.code">   
                                    <Readmore :longText="item.indication_en"/>  
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_022'" :code="column.code">
                                    <span v-if="$i18n.locale !== 'fr'" v-html="check_empty(array(item.classification_en))"  ></span>
                                    <span v-else v-html="check_empty(array(item.classification_fr))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'zinl_023'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>                         
                                <td v-else-if="column.Status && column.code == 'zinl_024'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)"  @click="drug_id = item.drug_id"></span>
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'zinl_025'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))" ></span>
                                </td>                                                         
                                <td v-else-if="column.Status && column.code == 'zinl_026'" :code="column.code">
                                    {{check_empty(item.specificity) }}
                                </td>     
                                <td v-else-if="column.Status && column.code == 'zinl_027'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td> 
                                <template v-else-if="column.Status && column.code == 'zinl_028'" >                             
                                    <td  :key="column.code+'_029'" code="zinl_029">
                                         {{check_empty(DDMMMYY(item.hta_process_date)) }} 
                                    </td>
                                    <td :key="column.code+'_030'" code="zinl_030">
                                        <span  :class="color_rembursement(item.hta_decision)">{{check_empty_with_locale(item.hta_decision, 'hta2_') }}</span>
                                    </td>
                                    <td :key="column.code+'_031'" code="zinl_031">
                                        {{check_empty(DDMMMYY(item.rembursement_process_date))}}
                                    </td>
                                    <td :key="column.code+'_032'" code="zinl_032">                                    
                                        <span v-html="rembursement_status_all_type(item)" ></span>
                                    </td>       
                                </template>

                                <td v-else-if="column.Status && column.code == 'zinl_033'" :code="column.code">
                                    {{check_empty_with_locale(item.zinl.decision,"decision_")}}
                                </td>
                                <template  v-else-if="column.Status && column.code == 'zinl_034'" > 
                                    <td :key="column.code+'_035'" :code="column.code"> 
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)" ></span>
                                    </td>
                                    <td :key="column.code+'_036'" :code="column.code">                                  
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)" ></span>
                                    </td>
                                    <td :key="column.code+'_037'" :code="column.code">                  
                                        {{datediff(item.ma_date, item.rembursement_process_date)}}
                                    </td>        
                                </template>                                          
                            </template>
                        </tr>
                    
                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>
                    
                </tbody>

            </table>
        </div>     
        <ModalHTAstatus :item="item_hta_status" />
        <ModalEssaisClinique :id="drug_id" agency="Zinl" />  
    </div>
</template>
 

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue';
import { isAdmin, rembursement_status_all_type,hta_process_status_2,rembursement_status_2, 
        overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, check_empty, nct_number, 
        treatment_line, datediff, array, color_rembursement, check_empty_with_locale} from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Zinl",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },  
    data : function() {
        return { 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
    computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        rembursement_status_all_type,
        rembursement_status_2,
        hta_process_status_2,   
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_rembursement,
        check_empty_with_locale,
        getResults(page = 1) {
            console.log(page)
        },     
        hta_status_modal(item){
            this.item_hta_status = item
        },   
        overview_regulatory_status,
        modifyPage
    },
}
</script>

<style  scoped>
.green {color:green}
.red {color:red}

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>
 